import React from "react";
import SearchBar from "./Searchbar";
import GraphRenderer from "../GraphRenderer"
import FeaturePanelComponent from "../FeaturePanel";
import { firestore, auth, getAuthenticatedUID } from "../../Utils/Firebase"
import { LinkDetail, EntityDetail } from "../../@types/GraphTypes";
import { rehydrateLinksArray } from "../../Utils/GraphTools"

function Graph() {

  const [baseNodes, setBaseNodes] = React.useState<Array<EntityDetail>>([]);
  const [baseLinks, setBaseLinks] = React.useState<Array<LinkDetail>>([]);
  const [filteredNodeIds, setFilteredNodeIds] = React.useState<number[]>([]);

  // Constructor, run at init to set listener to snapshot changes
  React.useEffect(() => {

    initResources()

    // @ts-ignore
  }, {});

  // helper function for init module
  const initResources = async () => {
    if (!auth || !auth.currentUser) return; 

    const uid = getAuthenticatedUID()
    // set listener when nodes/links data was changed, to immediately update client
    firestore.collection('nodes').doc(uid).onSnapshot(async (docNode) => {
      console.log("base nodes: ", baseNodes)
      const data = await docNode.data()
      if (!data) return
      setBaseNodes(Object.values(data))
    })
    firestore.collection('links').doc(uid).onSnapshot(async (docLink) => {
      console.log("base links: ", baseLinks)
      const data = await docLink.data()
      if (!data) return
      setBaseLinks(Object.values(data))
    })

  }

  if (baseNodes.length === 0) {
    return <div>Please Refresh, TODO?: add root-node creation feature?</div>
  }

  return (
    <div>
      <GraphRenderer filteredNodeIds={filteredNodeIds} baseNodes={baseNodes} baseLinks={rehydrateLinksArray(baseLinks)}/>
      <SearchBar
        entitiesList={baseNodes}
        setSelectedNodeIds={setFilteredNodeIds}
      />
      <FeaturePanelComponent baseNodes={baseNodes} baseLinks={baseLinks} refreshGraph={() => {}}/>
    </div>
  );
}

export default Graph;
