import React from "react";

// components
import "./App.css";
import GraphComponent from "./Components/Graph";
import MenuBarComponent from "./Components/MenuBar";
import StaticPageComponent from "./Components/StaticPage";
import { auth } from "./Utils/Firebase"

// context
import FeaturesProvider from "./Context/FeaturesProvider";
import HotkeyProvider from "./Context/HotkeyProvider";

console.log("version: ", process.env.REACT_APP_VERSION);

function App() {
  const [ userSignedIn, setUserSignIn ] = React.useState<boolean>(false);

  // Listener, will get activated when page is loaded, or when user signed in/out.
  auth.onAuthStateChanged( async (user: firebase.User | null) => {
    if (!user) setUserSignIn(false)
    else setUserSignIn(true)
  })

  // if user not signed in, set height of graph to 0%, giving space to intro page
  if (!userSignedIn) {
    // @ts-ignore
    document.getElementById('main-graph-container').style.height = 0;
  } else {
    // @ts-ignore
    document.getElementById('main-graph-container').style.height = '80%';
  }
  return (
    <div>
      <HotkeyProvider>
        <FeaturesProvider>
          {userSignedIn &&
            (
              <GraphComponent/>
            ) ||
            (<StaticPageComponent page="about" fullScreen={false} showExitButton={false} />)
          }
          <MenuBarComponent
            userSignedIn={userSignedIn}
          />
        </FeaturesProvider>
      </HotkeyProvider>
    </div>
  )
}

export default App;
