import { createContext } from "react";

export interface AppConfiguration {
  nightMode: boolean;
}

const GeneralContext = createContext({
  appConfiguration: { nightMode: false },
  setAppConfiguration: (config: AppConfiguration) => {},

});

export default GeneralContext;
