import React from "react";
import GeneralContext, { AppConfiguration } from "./GeneralContext";

const GeneralProvider = ({ children }: any) => {

  // soon...
  const [appConfiguration, setAppConfiguration] = React.useState<
    AppConfiguration
  >({ nightMode: false });

  return (
    <GeneralContext.Provider
      value={{
        appConfiguration,
        setAppConfiguration
      }}
    >
      {children}
    </GeneralContext.Provider>
  );
};

export default GeneralProvider;
