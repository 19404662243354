import { createContext } from "react";

//https://medium.com/digio-australia/using-the-react-usecontext-hook-9f55461c4eae
const HotkeyContext = createContext<{
  currentHotkeys: string[];
  setSelectedHotkey: any;
  clearSelectedHotkey: any;
  clearAllSelectedHotkey: any;
}>({
  currentHotkeys: [],
  setSelectedHotkey: (hotkeyName: string) => {},
  clearSelectedHotkey: (hotkeyName: string) => {},
  clearAllSelectedHotkey: () => {}
});

export default HotkeyContext;
