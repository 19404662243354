export const styleEntityDetailWideView = {
  position: "absolute",
  top: "2%",
  right: "2%",
  backgroundColor: "white",
  borderStyle: "solid",
  width: "20%",
  height: "80%",
  padding: "10px",
  overflowX: "scroll",
  overflowY: "scroll"
};

export const styleEntityDetailThinView = {
  position: "absolute",
  top: "0%",
  left: "1%",
  backgroundColor: "white",
  borderStyle: "solid",
  width: "98%",
  height: "23%",
  padding: "3px",
  overflowX: "scroll",
  overflowY: "scroll",
  display: "flex"
};

export const styleImageWideView = {
  width: "20%",
  height: "50%",
  float: "right",
  marginRight: 5
};

export const styleImageThinView = {
  width: "30px",
  height: "30px",
  position: "absolute",
  top: 50,
  left: 20
};

export const styleNameAreaThinView = {
  display: "block",
  width: "30%"
};
