import React from "react";
import { Button } from "antd";
import EntityDetailComponent from "../EntityDetail";
import EntitiesDetailComponent from "../EntitiesDetail";
import JsonImportDataComponent from "../JsonImportData";
import SearchConfigComponent from "../SearchConfig";
import StaticPageComponent from "../StaticPage";
import FeaturesContext from "../../Context/FeaturesContext";
import { FeatureDataPackage } from "../../@types";
import { styleExitButton } from "../../Styles/Components";

interface Props {
  baseNodes: any;
  baseLinks: any;
  refreshGraph: any;
}
function FeaturePanel(props: Props) {
  const { baseNodes, baseLinks, refreshGraph } = props;
  const {
    selectedFeature,
    setSelectedFeature
  }: {
    selectedFeature: FeatureDataPackage;
    setSelectedFeature: any;
  } = React.useContext(FeaturesContext);
  const exitButton = (): JSX.Element => {
    return (
      <Button
        onClick={e => {
          setSelectedFeature("NONE");
        }}
        // @ts-ignore
        style={{
          ...styleExitButton,
          position: "relative",
          top: 0,
          left: 0,
          marginLeft: 20
        }}
      >
        <span
          style={{
            textDecorationColor: "white",
            fontSize: 20,
            fontWeight: "bold"
          }}
        >
          X
        </span>
      </Button>
    );
  };
  switch (selectedFeature.selectedFeatureName) {
    case "JSON-IMPORT-DATA":
      return (
        <JsonImportDataComponent
          baseNodes={baseNodes}
          baseLinks={baseLinks}
          refreshGraph={refreshGraph}
          ExitButton={exitButton()}
        />
      );
    case "SEARCH-CONFIG":
      return <SearchConfigComponent ExitButton={exitButton()} />;
    case "NODE-DETAIL":
      return (
        <EntityDetailComponent
          entityDetailToShow={selectedFeature.entityDetailToShow}
          entitiesList={selectedFeature.baseNodes}
        />
      );
    case "NODES-DETAIL":
      return (
        <EntitiesDetailComponent
          entitiesDetailToShow={selectedFeature.entitiesDetailToShow}
          entitiesList={selectedFeature.baseNodes}
        />
      );
    case "STATIC-PAGE":
      return <StaticPageComponent />;
    default:
      return <div />;
  }
}

export default FeaturePanel;
