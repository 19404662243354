import React from "react";
import { Select } from "antd";
import { extractTagsFromNodesList } from "../../Utils/DetailViewTools";
import { debounce } from "lodash";
const { Option } = Select;

interface Props {
  entitiesList: any;
  updateChange: any;
  onBlur: any;
}

// tags selection is different from description, because I planned to make this a input with hints
const TagsSelection = (props: Props) => {
  const [dataSource, setDataSource] = React.useState<String[]>([]);
  const tagsList = extractTagsFromNodesList(props.entitiesList);

  const updateRecommendation = (value: string) => {
    if (value === "") return;
    const newValue = value
      .toString()
      .toUpperCase()
      .replace(/( |-)/g, "_");
    const newTags = tagsList.filter((tag: string) => tag.includes(newValue));
    setDataSource(newTags);
  };

  const debouncedUpdateRecommendation = React.useCallback(
    debounce(updateRecommendation, 300),
    []
  );

  const handleSearch = (value: string) => {
    debouncedUpdateRecommendation(value);
  };

  const handleSubmit = (value: String[]) => {
    props.updateChange(
      value[0]
        .toString()
        .toUpperCase()
        .replace(/( |-)/g, "_")
    );
  };

  return (
    <Select
      mode="tags"
      style={{ width: "100%" }}
      placeholder="FUNNY_STORIES"
      onSearch={handleSearch}
      onChange={handleSubmit}
      notFoundContent={""}
      autoFocus={true}
      value={[]}
    >
      {dataSource.map((ele: String) => {
        return <Option key={`${ele}`}>{ele}</Option>;
      })}
    </Select>
  );
};

export default TagsSelection;
