import { Select, Button } from "antd";
import React from "react";
import { debounce } from "lodash";
import { extractTagsFromNodesList } from "../../Utils/DetailViewTools";
import { styleExitButton } from "../../Styles/Components";
import { EntityDetail } from "../../@types/GraphTypes"

interface Props {
  entitiesList: EntityDetail[]
  setSelectedNodeIds: any
}

const SearchBar = (props: Props) => {

  // focus UI on the input box
  const [inFocus, setInFocus] = React.useState(false);
  const searchBar = React.createRef();
  const [termsToNodeIds, setTermsToNodeIds] = React.useState<{string: number[]} | {}>({})
  const [selectedTerms, setSelectedTerms] = React.useState<string[]>([]);
  const [suggestedTerms, setSuggestedTerms] = React.useState<string[]>([]);

  // set up search mode
  const enableSearchMode = () => {
     // set up searchTermsToNodeIds Dictionary, a term = tag/name
    const currentTermsToNodeIds = props.entitiesList.reduce( (master: any, entity: EntityDetail) => {
      const tags: string[] = entity.tags
      const name: string = entity.name.toUpperCase()
      if (!master[name]) master[name] = [entity.node_id]
      else master[name].push(entity.node_id)
      tags.forEach( tag => {
        if (!master[tag]) master[tag] = [entity.node_id]
        else master[tag].push(entity.node_id)
      })
      return master;
    }, {})

    setTermsToNodeIds(currentTermsToNodeIds)
    setInFocus(true);
  };

  // handler when filter selection UI changes
  const handleSearchInput = (termsArray: string[]) => {
    const nodeIdsSelected = termsArray.reduce( (master: number[], term: string) => {
      // @ts-ignore
      return master.concat(termsToNodeIds[term] || [])
    },[])
    props.setSelectedNodeIds(Array.from( new Set(nodeIdsSelected)))
    setSelectedTerms([...termsArray])
    setSuggestedTerms([])
  };

  // given a (incompleted) string, get the suggested terms to be used for filtering
  const onSearch = (searchString: string) => {
    const termsList = Object.keys(termsToNodeIds)
    const filteredTerms = termsList.filter(term =>
      term.includes(searchString.toUpperCase())
    );
    setSuggestedTerms(filteredTerms.slice(0, 11));
  };

  // update the source of debouncedOnSearch only when termsToNodeIds changes
  //    without this useCallback, debounceOnSearch will be looking at an old value of termsToNodeIds
  const debouncedOnSearch = React.useCallback(debounce(onSearch, 100), [
    termsToNodeIds
  ]);

  // handler when a term-suggestions ( the circles in the UI ) are clicked
  const onChangeFilterSingle = (term: string) => {
    const index = selectedTerms.indexOf(term);
    if (index >= 0) {
      selectedTerms.splice(index, 1);
    } else {
      selectedTerms.push(term);
    }
    handleSearchInput(selectedTerms);
  };

  // text view to show what terms are being selected when closed the term-filter UI ( input box + circles for selections)
  const generateTextViewFilter = () => {
    const texts = selectedTerms.map((value: string, index:number) => {
      return (
        <span
          key={`showing-term-display-${index}`}
          style={{
            backgroundColor: "rgba(168,168,168, 0.3)",
            fontWeight: "bold"
          }}
        >
          {value}
          {"   "}
        </span>
      );
    });
    return (
      <p
        style={{
          width: "100%",
          border: "2px solid lightblue"
        }}
        onClick={() => enableSearchMode()}
      >
        Search Your Loop Buddy:
        {texts}
      </p>
    );
  };

  // circle menu, to suggest tags/names of buddies
  const generateMenuItems = () => {
    let termListForFilter = suggestedTerms;

    // if no terms can be suggested, show the first 10 terms with highest amount of nodeIds
    if (suggestedTerms.length === 0) {
      const mostPopulartermsList = Object.entries(termsToNodeIds)
        .sort( ( a , b ) => 
          (a as [string,[number]])[1].length > (b as [string,[number]])[1].length ? 1 : -1
        )
        .map(entry => entry[0])
        .slice(0, 11)
      termListForFilter = mostPopulartermsList
    }

    const len = termListForFilter.length;
    const selectables = termListForFilter.map((term: string, index: number) => {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          key={`item-circle-menu-${index}`}
          style={{
            left: 45 - 50 * Math.cos((1 / (len - 1)) * index * Math.PI) + "%",
            bottom: 60 + 70 * Math.sin((1 / (len - 1)) * index * Math.PI) + "%",
            paddingRight: 80,
            paddingLeft: 20,
            border: "1px solid #5AD0A5",
            display: "inline-block",
            fontSize: term.length > 8 ? 12 : 15,
            color: "black",
            backgroundColor: "#5AD0A5",
            borderRadius: "50%"
          }}
          onClick={() => onChangeFilterSingle(term)}
        >
          {term}
        </a>
      );
    });

    return (
      <div
        className="circle"
        style={{
          position: "fixed",
          top: "30%",
          left: "10%",
          width: "80%"
        }}
      >
        {selectables}
      </div>
    );
  };
  // end circle menu

  if (!props.entitiesList) return <div />;
  return (
    <div>
      {inFocus && (
        <div
          style={{
            position: "fixed",
            top: "5%",
            left: "5%",
            width: "90%",
            height: "90%",
            backgroundColor: "rgba(0, 0, 0, 0.5)"
          }}
        >
          <Button
            // @ts-ignore
            style={styleExitButton}
            onClick={e => {
              setInFocus(false);
            }}
          >
            <span
              style={{
                textDecorationColor: "white",
                fontSize: 20,
                fontWeight: "bold"
              }}
            >
              X
            </span>
          </Button>
          <Select
            mode="multiple"
            // @ts-ignore
            ref={searchBar}
            value={selectedTerms}
            style={
              inFocus
                ? {
                  zIndex: 999,
                  position: "fixed",
                  top: "50%",
                  left: "20%",
                  width: "60%"
                }
                : {
                  width: "100%"
                }
            }
            onFocus={() => {
              // not pop text search on open search mode
              !inFocus &&
                searchBar &&
                searchBar.current &&
                // @ts-ignore
                searchBar.current.blur();
              setInFocus(true);
            }}
            placeholder="Name/Tag of person"
            editable={inFocus ? true : false}
            onSearch={debouncedOnSearch}
            open={false}
            onChange={handleSearchInput}
          ></Select>
          {generateMenuItems()}
        </div>
      )}

      {generateTextViewFilter()}
    </div>
  );
};

export default SearchBar;
