import React from "react";
import { Input, Button, message } from "antd";
import {
  styleEntityDetailThinView,
  styleEntityDetailWideView
} from "../../Styles/EntityDetail";
import FeaturesContext from "../../Context/FeaturesContext";
import { FeatureDataPackage } from "../../@types";
import { setNodesLinksArrayToFirestore } from "../../Utils/Firebase"
const { TextArea } = Input;

interface Props {
  baseNodes: any;
  baseLinks: any;
  refreshGraph: any;
  ExitButton: JSX.Element;
}

function JsonImportData(props: Props) {
  const { baseNodes, baseLinks, refreshGraph } = props;
  const {
    selectedFeature
  }: {
    selectedFeature: FeatureDataPackage;
    setSelectedFeature: any;
  } = React.useContext(FeaturesContext);
  const [value, setValue] = React.useState<string>("");

  const showSelectedEntityView = () => {
    if (!selectedFeature.entityDetailToShow) {
      return <p> Please select a node to add connections </p>;
    } else {
      return (
        <p>
          {" "}
          Adding connections to{" "}
          <b>{selectedFeature.entityDetailToShow.name} </b>
        </p>
      );
    }
  };

  /*
		update baseNodes, baseLinks
		write to server
		refresh the graph
	*/
  const createNewNeighborToNode = async (
    rootNode: number,
    jsonNodesToAppend: any
  ) => {
    const token = localStorage.getItem("userToken");
    if (token === null) return;

    const newNodes: any[] = [];
    const newLinks: any[] = [];

    // append nodes to base nodes/links
    jsonNodesToAppend.forEach((node: any) => {
      const maxNodeIndex =
        baseNodes.reduce((master: number, ele: { node_id: number }) => {
          if (master < ele.node_id) master = ele.node_id;
          return master;
        }, -1) || 0;
      const maxLinkIndex =
        baseLinks.reduce((master: number, ele: { link_id: number }) => {
          if (master < ele.link_id) master = ele.link_id;
          return master;
        }, -1) || 0;
      const newNodeID = maxNodeIndex + 1;
      const newLinkID = maxLinkIndex + 1;

      const newNode = {
        ...baseNodes[0],
        node_id: newNodeID,
        name: node.name,
        tags: node.tags,
        descriptions: node.descriptions
      };
      const newLink = {
        link_id: newLinkID,
        source: rootNode,
        target: newNode,
        type: "connected"
      };

      baseNodes.push(newNode);
      baseLinks.push(newLink);

      newNodes.push(newNode);
      newLinks.push(newLink);
    });

    setNodesLinksArrayToFirestore(newNodes, newLinks);
    refreshGraph();
    message.success("Successfully added neighbors");
  };

  const cleanJsonData = (userJsonData: any) => {
    if (!Array.isArray(userJsonData)) {
      userJsonData = [userJsonData];
    }
    for (let record of userJsonData) {
      record.name = record.name.replace(/[\u{0080}-\u{FFFF}]/gu, "");
      if (!record.tags) record.tags = [];
      if (!record.descriptions) record.descriptions = [];
    }
    return userJsonData;
  };

  const onPressAppendData = () => {
    if (!selectedFeature.entityDetailToShow) {
      message.error("Please select a node to add connections");
      // return;
    }
    let jsonData = value;
    try {
      jsonData = JSON.parse(jsonData);
      message.loading("Loading Data...");
      // append new node data to nodes, re-render
      createNewNeighborToNode(
        selectedFeature.entityDetailToShow,
        cleanJsonData(jsonData)
      );
    } catch (err) {
      console.log("Error parsing json:", err.message);
      message.error("Invalid Data, Please try again");
    }
  };

  return (
    <div
      // @ts-ignore
      style={
        window.innerWidth >= 700
          ? styleEntityDetailWideView
          : styleEntityDetailThinView
      }
    >
      {showSelectedEntityView()}
      <TextArea
        style={{
          height: "80%"
        }}
        onChange={(e: any) => setValue(e.target.value)}
      />
      <Button onClick={onPressAppendData}> Add connections </Button>
      {props.ExitButton}
    </div>
  );
}

export default JsonImportData;
