import React from "react";
import FeaturesContext from "./FeaturesContext";
import { FeatureDataPackage } from "../@types";
import { checkMatchingArraysValues } from "../Utils/GeneralTools";

const combineFeatures = (
  previousFeature: FeatureDataPackage,
  currentFeature: FeatureDataPackage
): FeatureDataPackage => {
  // do featuture combination here
  const featureCombination = [
    previousFeature.selectedFeatureName,
    currentFeature.selectedFeatureName
  ];
  // combination to import json data to append to selected node
  if (
    checkMatchingArraysValues(featureCombination, [
      "JSON-IMPORT-DATA",
      "NODE-DETAIL"
    ])
  ) {
    currentFeature = {
      ...currentFeature,
      ...previousFeature,
      selectedFeatureName: "JSON-IMPORT-DATA"
    };
  }
  return currentFeature;
};

//https://medium.com/digio-australia/using-the-react-usecontext-hook-9f55461c4eae
const FeaturesProvider = ({ children }: any) => {
  const setSelectedFeature = (featureDataPackage: FeatureDataPackage) => {
    if (selectedFeature.selectedFeatureName !== "none") {
      let currentFeature = featureDataPackage;
      let previousFeature = selectedFeature;
      // combine features && update result
      setSelectedFeatureState(combineFeatures(previousFeature, currentFeature));
    } else {
      setSelectedFeatureState(featureDataPackage);
    }
  };

  const [selectedFeature, setSelectedFeatureState] = React.useState<
    FeatureDataPackage
  >({ selectedFeatureName: "none" });

  return (
    <FeaturesContext.Provider value={{ selectedFeature, setSelectedFeature }}>
      {children}
    </FeaturesContext.Provider>
  );
};

export default FeaturesProvider;
