import React from "react";
import { Input, Button, message } from "antd";
import {
  styleEntityDetailThinView,
  styleEntityDetailWideView
} from "../../Styles/EntityDetail";
const { TextArea } = Input;

interface Props {
  ExitButton: JSX.Element;
}

function SearchConfig(props: Props) {
  let currentFilters = localStorage.getItem("searchConfig");
  if (!currentFilters) currentFilters = "[]";
  const defaultValue = JSON.parse(currentFilters).join("\n");

  const [value, setValue] = React.useState<string>(defaultValue);

  const setSearchConfig = () => {
    let tags = value.split(/[ \r\n\t]+/);
    tags = tags.filter(tag => tag.trim() !== "").map(tag => tag.toUpperCase());
    localStorage.setItem("searchConfig", JSON.stringify(tags));
    message.success("Successfully configured search tool, try now!");
  };

  return (
    <div
      // @ts-ignore
      style={
        window.innerWidth >= 700
          ? styleEntityDetailWideView
          : styleEntityDetailThinView
      }
    >
      <p> Please add Filter, separated by newline or spaces </p>
      <TextArea
        style={{
          height: "80%"
        }}
        onChange={(e: any) => setValue(e.target.value)}
        defaultValue={value}
      />
      <Button onClick={setSearchConfig}> Set Config </Button>
      {props.ExitButton}
    </div>
  );
}

export default SearchConfig;
