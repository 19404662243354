import React from "react";
import { Tabs, Input } from "antd";
import TagsSelection from "./TagsSelection";
import FeaturesContext from "../../Context/FeaturesContext";
import {
  styleEntityDetailThinView,
  styleEntityDetailWideView,
  styleImageThinView,
  styleImageWideView,
  styleNameAreaThinView
} from "../../Styles/EntityDetail";
import { setNodesLinksArrayToFirestore } from "../../Utils/Firebase"
import {EntityDetail } from "../../@types/GraphTypes"
const { TabPane } = Tabs;
const THIN_SCREEN_THRESHOLD = 900;

interface Props {
  entityDetailToShow: EntityDetail;
  entitiesList: any;
}

// used when a single node is selected on D3 graph 
//   check EntitiesDetail for multiple-node situtation
function EntityDetailClass(props: Props) {
  const { entityDetailToShow, entitiesList } = props;
  const [editName, activateEditName] = React.useState(false);
  const [editTags, activateEditTags] = React.useState(false);
  const [editDescription, activateEditDescription] = React.useState(false);
  const [facebookLink, setFacebookLink] = React.useState<string | null>(null);
  const { setSelectedFeature } = React.useContext(FeaturesContext);

  React.useEffect(() => {
    for (let description of entityDetailToShow.descriptions) {
      const url = description.match(
        /https?:\/\/(www\.)?facebook\.com\/\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
      );
      if (url) {
        setFacebookLink(url[0]);
      }
    }
  }, [editDescription]); // eslint-disable-next-line react-hooks/exhaustive-deps

  // refresh the panel UI
  const refreshSinglePanel = () => {
    setSelectedFeature({
      selectedFeatureName: "NODE-DETAIL",
      entityDetailToShow,
      baseNodes: entitiesList
    });
  };

  if (entityDetailToShow === null) return <div />;

  return (
    <div
      // @ts-ignore
      style={
        window.innerWidth >= THIN_SCREEN_THRESHOLD
          ? styleEntityDetailWideView
          : styleEntityDetailThinView
      }
    >
      <div
        className="entity-detail-name"
        style={{
          overflow: "auto",
          position: "relative",
          ...(window.innerWidth >= THIN_SCREEN_THRESHOLD
            ? {}
            : styleNameAreaThinView)
        }}
      >
        {editName ? (
          <Input
            style={{
              textAlign: "center",
              fontSize: 20,
              width: "70%"
            }}
            defaultValue={entityDetailToShow.name}
            autoFocus={true}
            onPressEnter={(obj: any) => {
              entityDetailToShow.name = obj.target.value;
              setNodesLinksArrayToFirestore([entityDetailToShow], [])
              refreshSinglePanel();
              // toggle edit mode
              activateEditName(!editName);
            }}
            onBlur={obj => {
              entityDetailToShow.name = obj.target.value;
              setNodesLinksArrayToFirestore([entityDetailToShow], [])
              refreshSinglePanel();
              // toggle edit mode
              activateEditName(!editName);
            }}
          />
        ) : (
          <span
            style={{
              textAlign: "left",
              fontSize: 20,
              width: "70%"
            }}
            onClick={() => activateEditName(!editName)}
          >
            {" "}
            {`*${entityDetailToShow.name}*`}
          </span>
        )}
        <img
          // @ts-ignore
          style={
            window.innerWidth >= 700 ? styleImageWideView : styleImageThinView
          }
          alt={"delete this node"}
          draggable={false}
          onClick={e =>
            //@ts-ignore
            window.onDeleteNodeRequest(entityDetailToShow)
          }
          src="/assets/garbage.png"
        />
      </div>
      <Tabs
        tabPosition={
          window.innerWidth >= THIN_SCREEN_THRESHOLD ? "top" : "left"
        }
        defaultActiveKey="tab-tags"
        style={{
          height: 1000,
          width: "68%",
          display: "inline"
        }}
      >
        <TabPane tab="Tags" key="tab-tags">
          {editTags ? (
            <TagsSelection
              entitiesList={entitiesList}
              updateChange={(tagsText: string) => {
                if (entityDetailToShow.tags.includes(tagsText)) return;
                entityDetailToShow.tags.push(tagsText);
                setNodesLinksArrayToFirestore([entityDetailToShow], [])
                refreshSinglePanel();
              }}
              onBlur={() => activateEditTags(false)}
            />
          ) : (
            <p onClick={() => activateEditTags(!editTags)}> Add Tag... </p>
          )}
          {entityDetailToShow.tags.length > 0 ? (
            entityDetailToShow.tags.map((tag, tagIndex) => (
              <div
                key={tag}
                style={{
                  backgroundColor: tagIndex % 2 === 0 ? "#F2F2F2" : "white",
                  position: "relative",
                  height: 50
                }}
              >
                <span> {tag} </span>
                <img
                  draggable={false}
                  style={{
                    position: "absolute",
                    right: 10,
                    marginLeft: 20
                  }}
                  alt={"delete this tag"}
                  src="/assets/garbage.png"
                  onClick={() => {
                    //@ts-ignore
                    entityDetailToShow.tags.splice(tagIndex, 1);
                    setNodesLinksArrayToFirestore([entityDetailToShow], [])
                    refreshSinglePanel();
                  }}
                />
              </div>
            ))
          ) : (
            <p>No tags for this node.</p>
          )}
        </TabPane>
        <TabPane tab="Description" key="tab-description">
          {editDescription ? (
            //@ts-ignore
            <Input
              placeholder={"Dis a cool dude."}
              onPressEnter={(txt: any) => {
                entityDetailToShow.descriptions.push(txt.target.value);
                activateEditDescription(false);
                setNodesLinksArrayToFirestore([entityDetailToShow], [])
              }}
              autoFocus={true}
            />
          ) : (
            <p onClick={() => activateEditDescription(!editDescription)}>
              {" "}
              Add Description...{" "}
            </p>
          )}

          {entityDetailToShow.descriptions.length > 0 ? (
            entityDetailToShow.descriptions.map((text, descriptionIndex) => (
              <div
                key={text}
                style={{
                  backgroundColor:
                    descriptionIndex % 2 === 0 ? "#F2F2F2" : "white",
                  position: "relative"
                }}
              >
                <p>{text}</p>
                <img
                  draggable={false}
                  style={{
                    marginLeft: 20,
                    marginBottom: 10
                  }}
                  alt={"delete this tag"}
                  src="/assets/garbage.png"
                  onClick={() => {
                    //@ts-ignore
                    entityDetailToShow.descriptions.splice(descriptionIndex, 1);
                    setNodesLinksArrayToFirestore([entityDetailToShow], [])
                    refreshSinglePanel();
                  }}
                />
              </div>
            ))
          ) : (
            <p> No description for this Node.</p>
          )}
        </TabPane>
        {facebookLink && (
          <TabPane tab="Facebook" key="tab-facebook">
            <iframe src={facebookLink} />
          </TabPane>
        )}
      </Tabs>
    </div>
  );
}

export default EntityDetailClass;
