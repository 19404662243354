export const styleExitButton = {
  position: "fixed",
  top: "40%",
  left: "45%",
  color: "#fff",
  background: "#ed3330",
  borderRadius: "5px",
  display: "inline-block",
  border: "none",
  zIndex: 9999
};
