import firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";
import { EntityDetail, LinkDetail } from "../@types/GraphTypes";

var firebaseConfig = {
  apiKey: "AIzaSyB3D0Uwkvj_eKSssA_klTLZnfqsThTG3ak",
  authDomain: "buddyloop-1fd38.firebaseapp.com",
  databaseURL: "https://buddyloop-1fd38.firebaseio.com",
  projectId: "buddyloop-1fd38",
  storageBucket: "buddyloop-1fd38.appspot.com",
  messagingSenderId: "340849444439",
  appId: "1:340849444439:web:b974da7ac3d465caf60512",
  measurementId: "G-P7TG110T78"
};
firebase.initializeApp(firebaseConfig);


// STATIC MODULES
export default firebase
export const firestore = firebase.firestore()
export const auth = firebase.auth();
export const firebaseGoogleAuthProvider = new firebase.auth.GoogleAuthProvider();
export const AUTH = firebase.auth.Auth


// HELPER FUNCTIONS

const getAuthenticatedUID = (): string => {
  if (auth && auth.currentUser && auth.currentUser.uid) return auth.currentUser.uid;
  else throw new Error("Unauthenticated")
}

// set firestore document of authenticated user with nodes+links array in batch
const setNodesLinksArrayToFirestore = (nodeEntities: EntityDetail[], linkEntities: LinkDetail[]) => {

  const nodeUpdateDict = nodeEntities.reduce( (master: any, entity: EntityDetail) => {
    master[entity.node_id] = {
      node_id: entity.node_id,
      descriptions: entity.descriptions,
      tags: entity.tags,
      name: entity.name
    }
    return master 
  }, {})

  const linkUpdateDict = linkEntities.reduce( (master: any, link: LinkDetail) => {
    master[link.link_id] = {
      link_id: link.link_id,
      source: link.source,
      target: link.target,
      type: link.type
    }
    return master 
  }, {})

  // credit: https://stackoverflow.com/questions/56701633/can-i-batch-together-an-add-and-a-set-operation-in-cloud-firestore
  const batch = firestore.batch()
  const nodeDocumentRef = firestore.collection("nodes").doc(getAuthenticatedUID());
  const linkDocumentRef = firestore.collection("links").doc(getAuthenticatedUID());

  batch.set(nodeDocumentRef, nodeUpdateDict, { merge: true })
  batch.set(linkDocumentRef, linkUpdateDict, { merge: true })

  batch.commit()

}

// batch-delete the nodes/links data on firestore
const deleteNodesLinksArrayOnFirestore = (nodeIds: Number[], linkIds: Number[]) => {

  const nodeDeleteDict = nodeIds.reduce( (master: any, id: Number) => {
    master[`${id}`] = firebase.firestore.FieldValue.delete()
    return master;
  }, {})

  const linkDeleteDict = linkIds.reduce( (master: any, id: Number) => {
    master[`${id}`] = firebase.firestore.FieldValue.delete()
    return master;
  }, {})


  // credit: https://stackoverflow.com/questions/56701633/can-i-batch-together-an-add-and-a-set-operation-in-cloud-firestore
  const batch = firestore.batch()
  const nodeDocumentRef = firestore.collection("nodes").doc(getAuthenticatedUID());
  const linkDocumentRef = firestore.collection("links").doc(getAuthenticatedUID());


  batch.set(nodeDocumentRef, nodeDeleteDict, { merge: true })
  batch.set(linkDocumentRef, linkDeleteDict, { merge: true })

  batch.commit()


}

export { getAuthenticatedUID, setNodesLinksArrayToFirestore, deleteNodesLinksArrayOnFirestore }
