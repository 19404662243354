import React from "react";
import ReactDOM from "react-dom"
import isEmpty from "lodash/isEmpty";
import {
  generateDictNodeConnection,
  generateDisplayableGraph,
  checkNodesConnected,
  setNodeOpacity,
  setActivatedLinkMode,
  getNodesOnPathsFromSelectedNodeToRoot,
  getNodeIdDrillDown
} from "../../Utils/GraphTools";
import { setNodesLinksArrayToFirestore, deleteNodesLinksArrayOnFirestore } from "../../Utils/Firebase"
import FeaturesContext from "../../Context/FeaturesContext";
import HotkeyContext from "../../Context/HotkeyContext";
import { message } from "antd";
import { EntityDetail, LinkDetail } from "../../@types/GraphTypes"

// somehow, when Graph updates, the states get reset ?
let filters = { names: [], tags: [] };

interface Props {
  baseNodes: EntityDetail[]
  baseLinks: LinkDetail[]
  filteredNodeIds: number[]
}

function Graph(props: Props) {
  const { baseNodes, baseLinks } = props;
  const [selectedNodeIds, setSelectedNodeIds] = React.useState<number[]>([]);
  const [graphStatus, setGraphStatus] = React.useState("");
  const [rootNodeIdForLinking, setRootNodeIdForLinking] = React.useState<number | null>(null);
  const { currentHotkeys } = React.useContext(HotkeyContext);
  const { setSelectedFeature, selectedFeature } = React.useContext(
    FeaturesContext
  );

  React.useEffect(() => {

    // in case D3.js display controller is slower to load than the App
    if (!window.updateGraphData) {
      // wait until d3 finishes loading
      setGraphStatus("Graph Is Loading...");
      let graphLoad = setInterval(() => {
        if (window.updateGraphData) {
          // set graph loaded
          setGraphStatus("Graph Is Loaded.");
          // update graph
          filterEntities();
          // clear the checking cycle
          clearInterval(graphLoad);
        }
      }, 400);
    } else {
      // This happens most of the time.
      filterEntities({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseNodes, baseLinks]);

  // update the graph when NodeDetailPanel change single selected node's detail ( name, tags... )
  React.useEffect(() => {
    filterEntities({ clearDisplay: true });
    for (let node_id of selectedNodeIds) {
      setNodeOpacity(node_id, 0.5);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFeature]);



  /*
    Listener to D3 HTML Controller, when a node entity is selected

    detect a node select, do: 
      - if in node-linking mode => link selected node to starter node
      - Else: 
        + Reset colors of all previously selected node
        + Update color of the new selected node
        + Append the add icon to the selected node
	*/
  window.onNodesSelectExtension = (nodeSelected: EntityDetail) => {
    const nodeSelectedId = nodeSelected.node_id

    // if we are in "link a node" phase ( user press the link-this-node from source node, and wants to select the destination node )
    if (rootNodeIdForLinking != null) {
      window.onLinkNodesRequest(nodeSelected);
      return;
    }

    // if not linking-node phase, we are selecting/highlighting the node
    const isShiftSelected = currentHotkeys.includes("Shift");
    const alreadySelected = selectedNodeIds.includes(nodeSelectedId);

    // if deselecting, resulting in no node selected
    if (alreadySelected && selectedNodeIds.length === 1) {
      // deselect node, go back to normal mode

      ReactDOM.unstable_batchedUpdates(() => {
        setNodeOpacity(nodeSelected.node_id, 0.1);
        setSelectedNodeIds([]);
        window.focusOnNodes([], true);
        setSelectedFeature({
          selectedFeatureName: "none"
        });
      })
    } else {

      // hold shift to do multiple-selection 
      if (!isShiftSelected) {

        // doing single-selection
        for (let node_id of selectedNodeIds) {
          setNodeOpacity(node_id, 0.1);
        }

        //if not shift selected && selectedNodes includes node selected
        //  => deselect nodes, hide the node-detail panel feature
        if (selectedNodeIds.length === 1 && alreadySelected) {
          ReactDOM.unstable_batchedUpdates(() => {
            setSelectedNodeIds([]);
            window.focusOnNodes([], true);
            setSelectedFeature({
              selectedFeatureName: "none"
            })
          })
        } else {
          // if not shift selected && selectedNodes NOT includes node selected
          //   => replace previously selected Nodes with new node, ensure node-single-detail panel is enabled

          ReactDOM.unstable_batchedUpdates(() => {
            setSelectedNodeIds([nodeSelectedId]);
            window.focusOnNodes([nodeSelected], true);
            setSelectedFeature({
              selectedFeatureName: "NODE-DETAIL",
              entityDetailToShow: nodeSelected,
              baseNodes
            });
            setNodeOpacity(nodeSelected.node_id, 0.5);
          })

        }

      } else if (isShiftSelected) {
        // if shift selected && selectedNodes includes node selected
        //   => splice node selected from selected nodes, ensure node-multiple-detail panel is enabled
        if (alreadySelected) {
          ReactDOM.unstable_batchedUpdates(() => {
            setNodeOpacity(nodeSelected.node_id, 0.1);
            selectedNodeIds.splice(selectedNodeIds.indexOf(nodeSelectedId), 1);
            setSelectedNodeIds([...selectedNodeIds]);

            const selectedNodesEntities = baseNodes.filter( node => selectedNodeIds.includes(node.node_id))
            window.focusOnNodes(selectedNodesEntities, true);
            setSelectedFeature({
              selectedFeatureName: "NODES-DETAIL",
              entitiesDetailToShow: selectedNodesEntities,
              baseNodes
            });
          })
        } else {
          // if shift selected && selectedNodes not includes node selected
          //   => push node selected into selected nodes, ensure node-multiple-detail panel is enabled
          ReactDOM.unstable_batchedUpdates(() => {
            setNodeOpacity(nodeSelected.node_id, 0.5);
            selectedNodeIds.push(nodeSelectedId);
            setSelectedNodeIds([...selectedNodeIds]);

            const selectedNodesEntities = baseNodes.filter( node => selectedNodeIds.includes(node.node_id))
            window.focusOnNodes(selectedNodesEntities, true);
            setSelectedFeature({
              selectedFeatureName: "NODES-DETAIL",
              entitiesDetailToShow: selectedNodesEntities,
              baseNodes
            });
          })
        }
      }
    }

  };

  // connected to D3 html controller, when the request to llink node is triggered
  window.onLinkNodesRequest = async (selectedNodeForLinking: EntityDetail) => {
    // highlight feed back for icon linking
    // detect mouse click on node =>
    //     if mode is doing linking => perform link => refresh
    if (rootNodeIdForLinking != null) {
      if (!currentHotkeys.includes("Shift")) {
        // cancel/finish linking
        setRootNodeIdForLinking(null);
        setActivatedLinkMode((rootNodeIdForLinking as number), false);
      }
      // if click on a non-selected node
      if (rootNodeIdForLinking !== selectedNodeForLinking.node_id) {
        // NEED TO DETERMINE START AND END
        const groupTargetSourceIds = [
          selectedNodeForLinking.node_id,
          (rootNodeIdForLinking as number)
        ];
        const existingLinkIndex = baseLinks.findIndex(
          l =>
            groupTargetSourceIds.includes(getNodeIdDrillDown(l.source)) &&
            groupTargetSourceIds.includes(getNodeIdDrillDown(l.target))
        );
        if (existingLinkIndex >= 0) {
          // check if these nodes will become orphans
          //   if yes, block user from breaking the link
          const possibleNewLinks = [
            ...baseLinks.slice(0, existingLinkIndex),
            ...baseLinks.slice(existingLinkIndex + 1)
          ];
          const possibleNodesDict = generateDictNodeConnection(
            possibleNewLinks
          );
          const selectedNodeIsConnected = checkNodesConnected(
            possibleNodesDict,
            (rootNodeIdForLinking as number),
            0,
            []
          );
          const selectedForLinkingIsConnected = checkNodesConnected(
            possibleNodesDict,
            selectedNodeForLinking.node_id,
            0,
            []
          );
          // if both are still connected to root after after cutting the link, then proceed cutting the link
          if (selectedNodeIsConnected && selectedForLinkingIsConnected) {
            deleteNodesLinksArrayOnFirestore([], [baseLinks[existingLinkIndex].link_id])
            baseLinks.splice(existingLinkIndex, 1);
          } else {
            message.error("Not Allowed, This will create orphan Nodes.");
          }
        } else {
          const maxIndex = baseLinks.reduce((master, ele) => {
            if (master < ele.link_id) master = ele.link_id;
            return master;
          }, -1);
          const newLink: LinkDetail = {
            link_id: maxIndex + 1,
            source: selectedNodeForLinking.node_id,
            target: (rootNodeIdForLinking as number),
            type: "connected"
          };
          baseLinks.push(newLink);

          setNodesLinksArrayToFirestore([], [newLink])
        }
        filterEntities();
      }
      return;
    } else {
      // start linking
      setRootNodeIdForLinking(selectedNodeForLinking.node_id);
      setActivatedLinkMode(selectedNodeForLinking.node_id, true);
    }
  };

  /*
    connected to D3 html controller, when the request to add new node is triggered
    upon selecting add node:
      graph will create a new node
      focus on that Node
      side menu will allow set name (with default value)
      side menu will allow set tags, description
   */
  window.onAddNodeRequest = async (selectedNode: EntityDetail) => {
    // get max node ID + 1
    const nodeIds = baseNodes.map(e => e.node_id)
    nodeIds.sort((x, y) => x > y ? -1 : 1)
    const maxNodeIndex = nodeIds[0] || 0
    const newNodeID = maxNodeIndex + 1;

    // get max link ID + 1
    const linkIds = baseLinks.map(e => e.link_id)
    linkIds.sort((x, y) => x > y ? -1 : 1)
    const maxLinkIndex = linkIds[0] || 0
    const newLinkID = maxLinkIndex + 1;

    const newNode: EntityDetail = {
      node_id: newNodeID,
      name: `Node${newNodeID}`,
      tags: [],
      descriptions: []
    };
    const newLink: LinkDetail = {
      link_id: newLinkID,
      source: selectedNode.node_id,
      target: newNode.node_id,
      type: "connected"
    };

    baseNodes.push(newNode);
    baseLinks.push(newLink);
    setNodesLinksArrayToFirestore([newNode], [newLink])
  };

  // In NodeDetail Panel, user clicks the trash can -> can remove the node
  window.onDeleteNodeRequest = async (nodeDetail: EntityDetail) => {
    if (!nodeDetail) return;
    const foundIndex = baseNodes.findIndex(n => n.node_id === nodeDetail.node_id);
    if (foundIndex) {
      // check if removing this node will create any orphan
      let possibleNewLinks = JSON.parse(JSON.stringify(baseLinks));
      for (let i = 0; i < possibleNewLinks.length; i++) {
        const currentLink = possibleNewLinks[i];
        if (
          [getNodeIdDrillDown(currentLink.source), getNodeIdDrillDown(currentLink.target)].includes(nodeDetail.node_id)
        ) {
          possibleNewLinks.splice(i, 1);
          i--;
        }
      }
      const possibleNodesDict = generateDictNodeConnection(possibleNewLinks);
      const currentNeighbors: number[] = generateDictNodeConnection(baseLinks)[
        nodeDetail.node_id
      ];
      // check if this deletion will create orphan neighbors
      if (currentNeighbors) {
        const hasOrphanNeighbor = currentNeighbors.find(
          neighbor => !checkNodesConnected(possibleNodesDict, neighbor, 0, [])
        );
        if (hasOrphanNeighbor) {
          message.error("Cannot Delete, this will create orphan nodes.");
          return;
        }
      }

      // remove node
      let nodeIdsToRemove = [nodeDetail.node_id];
      let linkIdsToRemove = [];
      baseNodes.splice(foundIndex, 1);
      // remove links associated with this node
      for (let i = 0; i < baseLinks.length; i++) {
        const currentLink = baseLinks[i];
        if (
          [getNodeIdDrillDown(currentLink.source), getNodeIdDrillDown(currentLink.target)].includes(nodeDetail.node_id)
        ) {
          linkIdsToRemove.push(currentLink.link_id);
          // linkIdsToRemove.push(currentLink.link_id);
          baseLinks.splice(i, 1);
          i--;
        }
      }

      window.focusOnNodes([], true);
      setSelectedNodeIds([]);
      setSelectedFeature({
        selectedFeatureName: "none"
      });

      deleteNodesLinksArrayOnFirestore(nodeIdsToRemove, linkIdsToRemove)
    }
  };

  /* Contain an Algorithm to get the neighbor entities
     radius-based node-filter to only show nodes in path
     from root user to the selected entity

     read the current nodes/links, apply filters to them, and update the D3 display controller

     @param: config: [ clearColor: boolean, clearCache: boolean]
     clearColor: clear all color effects on graph
     clearCache: restart the entire graph d3 HTML elements & data for display
     clearDisplay: restart graph d3's injected nodes/links data for display
  */
  const filterEntities = (config: any = {}) => {
    const nodeIdsForHighlight = props.filteredNodeIds
    if (config.clearColor) clearColorFilteredEntities(baseNodes);
    if (config.clearCache) window.forceClearCache();
    if (config.clearDisplay) {
      updateDataAndGraph([], []);
    }

    if (nodeIdsForHighlight.length) {
      // display graph with name/tag filter
      updateGraphWithCollapsedData({
        additionalNodeIdsForHighlight: nodeIdsForHighlight,
        rootNodeId: 0
      });
    } else {
      // display graph with no name/tag filter
      updateGraphWithCollapsedData({
        // @ts-ignore
        additionalNodeIdsForHighlight: [],
        rootNodeId: 0
      });

    }
  };

  /* display graph of root node + specified group of nodes. Also perhaps highlights on some nodes
  
    @params: rootNodeId: should be 0
    @params: additionalNodeIdsForHighlight: using the entity-search bar to set filters, these are the nodes that are filtered by that bar
    @params: selectedNodeIds: selected nodes by user using the graph UI

    @ output: d3 graph is updated with a collapsed view of graph, displaying only the neccessary nodes
  */

  const updateGraphWithCollapsedData = ({
    rootNodeId,
    additionalNodeIdsForHighlight,
  }: { rootNodeId: number, additionalNodeIdsForHighlight: number[]}) => {

    const nodesForDisplays = additionalNodeIdsForHighlight.concat(selectedNodeIds)

    // generate Nodes OnPath Between "nodesForDisplay" and root
    getNodesOnPathsFromSelectedNodeToRoot(
      baseNodes,
      baseLinks,
      nodesForDisplays,
      baseLinks,
      0
    );

    const {
      nodes: filteredNodes,
      links: filteredLinks
    } = generateDisplayableGraph(
      baseNodes,
      baseLinks,
      nodesForDisplays,
      rootNodeId
    );
    // load new data into d3 display
    updateDataAndGraph(filteredNodes, filteredLinks);

    // set focus nodes on d3
    window.focusOnNodes(selectedNodeIds.map(node_id => baseNodes.find( e => e.node_id === node_id )), true);

    // set highlighted colors for nodes highglighted by filters search bar
    updateColorFilteredEntities(additionalNodeIdsForHighlight);
  };

  // sync d3 display with nodes data and links data
  const updateDataAndGraph = (newNodes: EntityDetail[], newLinks: LinkDetail[]) => {
    if (window.updateGraphData) {
      window.updateGraphData(newNodes, newLinks);
    } else {
      console.log("ERROR, window.updateGraphData is not initialized.")
      console.log({ newNodes, newLinks, graphStatus })
    }
  };

  const updateColorFilteredEntities = (nodeIds: number[]) => {
    nodeIds.forEach(node_id => setNodeOpacity(node_id, 0.5));
  };

  const clearColorFilteredEntities = (nodes: EntityDetail[]) => {
    nodes.forEach(node => {
      if (!selectedNodeIds[0] || selectedNodeIds[0] !== node.node_id) {
        setNodeOpacity(node.node_id, 0.1);
      }
    });
  };

  return (
    <div>
      {graphStatus !== "" && (
        <p
          style={{
            fontStyle: "italic",
            marginLeft: 20
          }}
        >
          {" "}
          {graphStatus}{" "}
        </p>
      )}
    </div>
  );
}

export default Graph;
