import { createContext } from "react";
import { FeatureDataPackage } from "../@types";

//https://medium.com/digio-australia/using-the-react-usecontext-hook-9f55461c4eae
const FeaturesContext = createContext<{
  selectedFeature: FeatureDataPackage;
  setSelectedFeature: any;
}>({
  selectedFeature: { selectedFeatureName: "none" },
  setSelectedFeature: (featureDataPackage: FeatureDataPackage) => {}
});

export default FeaturesContext;
