const extractTagsFromNodesList = nodesList => {
  // get tags and names options
  return nodesList.reduce((master, ele) => {
    ele.tags.forEach(tag => {
      if (!master.includes(tag)) master.push(tag);
    });
    return master;
  }, []);
};

export { extractTagsFromNodesList };
