import React from "react";
import { Button } from "antd";
import FeaturesContext from "../../Context/FeaturesContext";
const ANIMATION_DURATION = 180;

interface Props {
  page?: string;
  fullScreen?: boolean;
  showExitButton?: boolean;
}

function StaticPage(props: Props) {
  const { selectedFeature, setSelectedFeature } = React.useContext(
    FeaturesContext
  );

  // TODO: can be cleaned with new Nullish Operator of Chrome. not yet updated in babel :(
  const fullScreen = props.fullScreen != null ? props.fullScreen : true
  const page = props.page
  const showExitButton = props.showExitButton != null ? props.showExitButton : true;
  const [height, setHeight] = React.useState("0");
  const [content, setContent] = React.useState(
    ""
  );
  React.useEffect(() => {
    setTimeout(() => {
      setHeight(fullScreen ? "100%" : "80%");
    }, ANIMATION_DURATION);
    //@ts-ignore
  }, {});

  const getPage = async () => {
    const res = await fetch(`/static_pages/${selectedFeature.page || page}.html`);
    const text = await res.text();
    setContent(text);
  };

  React.useState(async () => {
    await getPage();
    // @ts-ignore
  }, [selectedFeature]);

  // listen to feature panel
  // if legit, load page, change css to show things
  return (
    <div
      style={{
        height,
        transition: `height ${ANIMATION_DURATION}ms linear`,
        width: "100%",
        position: fullScreen ? "absolute" : "relative",
        bottom: 0,
        backgroundColor: "white",
        zIndex: 10
      }}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: content
        }}
      />
      {showExitButton && (
        <Button
          style={{
            position: "fixed",
            top: 20,
            right: 20,
            color: "#fff",
            textTransform: "uppercase",
            background: "#ed3330",
            borderRadius: "5px",
            display: "inline-block",
            border: "none"
          }}
          onClick={(e: any) => {
            setHeight("0");
            setTimeout(() => {
              setSelectedFeature({
                selectedFeatureName: "none"
              });
            }, ANIMATION_DURATION);
          }}
        >
          <span
            style={{
              textDecorationColor: "white",
              fontSize: 20,
              fontWeight: "bold"
            }}
          >
            X
        </span>
        </Button>
      )}
    </div>
  );
}

export default StaticPage;
