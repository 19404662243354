import React from "react";
import { Tabs } from "antd";
import FeaturesContext from "../../Context/FeaturesContext";
import TagsSelection from "../EntityDetail/TagsSelection";
import {
  styleEntityDetailThinView,
  styleEntityDetailWideView,
  styleNameAreaThinView
} from "../../Styles/EntityDetail";
import { setNodesLinksArrayToFirestore } from "../../Utils/Firebase"
import { EntityDetail } from "../../@types/GraphTypes"
const { TabPane } = Tabs;
const THIN_SCREEN_THRESHOLD = 900;

interface Props {
  entitiesDetailToShow: EntityDetail[]
  entitiesList: any;
}

// used when multiple nodes are selected on D3 graph, display the side panel for editting
//   check EntityDetail Component for single node situation
function EntitiesDetail(props: Props) {

  // entities that can be shown on the detailed panels
  const { entitiesDetailToShow, entitiesList } = props;
  const entityDetailToShow = entitiesDetailToShow[0];

  // set up state system for tag-editting in multi-node (entities) editting
  const [editTags, activateEditTags] = React.useState(false);

  // update the current-feature on the page
  const { setSelectedFeature } = React.useContext(FeaturesContext);


  const refreshSinglePanel = () => {
    setSelectedFeature({
      selectedFeatureName: "NODES-DETAIL",
      entitiesDetailToShow,
      baseNodes: entitiesList
    });
  };

  if (!entitiesDetailToShow) return <div />;

  return (
    <div
      // @ts-ignore
      style={
        window.innerWidth >= THIN_SCREEN_THRESHOLD
          ? styleEntityDetailWideView
          : styleEntityDetailThinView
      }
    >
      <div
        className="entities-detail-name"
        style={{
          overflow: "auto",
          position: "relative",
          ...(window.innerWidth >= THIN_SCREEN_THRESHOLD
            ? {}
            : styleNameAreaThinView)
        }}
      >
        <span
          style={{
            textAlign: "left",
            fontSize: 20,
            width: "70%"
          }}
        >
          {" "}
          {`*${entitiesDetailToShow.map(entity => entity.name)}*`}
        </span>
      </div>
      <Tabs
        tabPosition={
          window.innerWidth >= THIN_SCREEN_THRESHOLD ? "top" : "left"
        }
        defaultActiveKey="tab-tags"
        style={{
          height: 1000,
          width: "68%",
          display: "inline"
        }}
      >
        <TabPane tab="Tags" key="tab-tags">
          {editTags ? (
            <TagsSelection
              entitiesList={entitiesList}
              updateChange={(tagsText: string) => {
                entitiesDetailToShow.forEach(entity => {
                  if (entity.tags.includes(tagsText)) return;
                  entity.tags.push(tagsText);
                });
                // call the api for update
                console.log("updating the tags")
                setNodesLinksArrayToFirestore(entitiesDetailToShow,[])
                // refresh the panel
                refreshSinglePanel();
              }}
              onBlur={() => activateEditTags(false)}
            />
          ) : (
            <p onClick={() => activateEditTags(!editTags)}> Add Tag... </p>
          )}
          {entityDetailToShow.tags.length > 0 ? (
            // get the mutual tags of selected entities
            entitiesDetailToShow

              .reduce((master: string[], entity: EntityDetail) => {
                for (let tag of master) {
                  if (!entity.tags.includes(tag)) {
                    master.splice(master.indexOf(tag), 1);
                  }
                }
                return master;
              }, entitiesDetailToShow[0].tags)

              .map((tag: string, tagIndex: number) => (
                <div
                  key={tag}
                  style={{
                    backgroundColor: tagIndex % 2 === 0 ? "#F2F2F2" : "white",
                    position: "relative",
                    height: 50
                  }}
                >
                  <span> {tag} </span>
                  <img
                    draggable={false}
                    style={{
                      position: "absolute",
                      right: 10,
                      marginLeft: 20
                    }}
                    alt={"delete this tag"}
                    src="/assets/garbage.png"
                    onClick={() => {
                      entitiesDetailToShow.forEach(entity => {
                        if (!entity.tags.includes(tag)) return;
                        entity.tags.splice(entity.tags.indexOf(tag), 1);
                      });
                      // call the api for update
                      setNodesLinksArrayToFirestore(entitiesDetailToShow, [])

                      // refresh the panel
                      refreshSinglePanel();
                    }}
                  />
                </div>
              ))
          ) : (
            <p>No tags for this node.</p>
          )}
        </TabPane>
      </Tabs>
    </div>
  );
}

export default EntitiesDetail;
