import { EntityDetail, LinkDetail } from "../@types/GraphTypes";

const getNodeIdDrillDown = (linkConnectedKey: EntityDetail | number): number => {
  if (typeof linkConnectedKey === "number") return linkConnectedKey
  else return (linkConnectedKey as EntityDetail).node_id;
}

// D3 mutates the link arrays, source/target key will point to memory address of respective node object
//   This module will rehydrate/de-mutate, returning linkArray with source/target to type Int <instead of> type EntityDetail
const rehydrateLinksArray = (linksArray: LinkDetail[]): LinkDetail[] => {
  return linksArray
  .map((link: any) => {
    link.source = getNodeIdDrillDown(link.source)
    link.target = getNodeIdDrillDown(link.target)
    return link
  })
}

/*
	generate the immediate connections from a node to neighbors for quick look up of immediate connection eg: {
		D: ['X', 'A', 'B'],
		A: ['H', 'K']
		...
	}
*/
const generateDictNodeConnection = (linksData: LinkDetail[]): {number?: number[]} | any => {
  if (!linksData || linksData.length === 0) return {};

  const dictNodeIdsConnections: {number?: number[]} =  linksData.reduce((master: any, link: LinkDetail) => {
    // create array if not exist
    if (master[getNodeIdDrillDown(link.source)] == null)
      master[getNodeIdDrillDown(link.source)] = [];
    if (master[getNodeIdDrillDown(link.target)] == null)
      master[getNodeIdDrillDown(link.target)] = [];

    // add in the neighbors, uniquely, should consider A Set
    if (!master[getNodeIdDrillDown(link.source)].includes(getNodeIdDrillDown(link.target))) {
      master[getNodeIdDrillDown(link.source)].push(getNodeIdDrillDown(link.target));
    }
    if (!master[getNodeIdDrillDown(link.target)].includes(getNodeIdDrillDown(link.source))) {
      master[getNodeIdDrillDown(link.target)].push(getNodeIdDrillDown(link.source));
    }
    return master;
  }, {});

  return dictNodeIdsConnections

};

/*
	generate an array of neighbor within "layer" node-radius
*/
const findNeighborXLayer = (layer: number, nodesDict: any, nodeID: any) => {
  // convert any number ( including negative ) => string
  // since a[-1] is not ok for js, but a["-1"] is okay
  nodeID += "";
  let setUniqueNodeID: any = new Set(nodesDict[nodeID]);
  layer--;
  for (let i = 0; i < layer; i++) {
    for (let nodeID of Array.from(setUniqueNodeID)) {
      // @ts-ignore
      setUniqueNodeID = new Set([...setUniqueNodeID, ...nodesDict[nodeID]]);
    }
  }
  return Array.from(setUniqueNodeID);
};

/*
	receive 2 array of ids
	return: array of intersecting IDs
*/
const getOverlapIds = (idArrayA: number[], idArrayB: number[]) => {
  return idArrayA.filter(id => idArrayB.includes(id));
};

/*
  check if a target node is directly/indirectly connected to dest
  params: nodesDict: map of nodeId => immediate neighbors
            ( result of generateDictNodeConnection above )
          target: target node ID
          source: target node Source ID
          visited: empty Array of Ids
*/
const checkNodesConnected = (
  nodesDict: any,
  target: number,
  dest: number,
  visited: number[]
) => {
  if (
    !nodesDict[target] ||
    nodesDict[target].length === 0 ||
    visited.includes(target)
  )
    return false;
  visited.push(target);
  if (target === dest) return true;
  const currentNeighbors = nodesDict[target];
  if (currentNeighbors.includes(dest)) return true;
  for (let neighbor of currentNeighbors) {
    if (checkNodesConnected(nodesDict, neighbor, dest, visited)) return true;
  }
  return false;
};

// given the nodes in "NewNodes", see what nodes are in between newNodes to Root, add them into newNodes too.
/*
  this function helps determine what nodes to be displayed on graph. We need to display 
    - root Nodes 
    - root's neighbors 
    - selected Nodes 
    - selected Nodes' neighbors 
    - nodes on path from root -> selected Nodes
  result: newNodes gets appended with 0 or more nodes
*/
const getNodesOnPathsFromSelectedNodeToRoot = (
  baseNodes: any,
  baseLinks: any,
  newNodeIds: any,
  newLinks: any,
  rootNodeId: number
) => {
  const nodesDict = generateDictNodeConnection(baseLinks);

  const connectingNodes: any = [];
  // algorithm to find nodes between root -> selected nodes in the shortest path.
  for (let node_id of newNodeIds) {
    // skip root nodes, we need nodes between root -> selected nodes.
    if (node_id === 0) continue;

    let layer = 1;
    let layerFromRoot: any = findNeighborXLayer(layer, nodesDict, rootNodeId);
    let layerFromNode: any = findNeighborXLayer(layer, nodesDict, node_id);
    let overlaps = getOverlapIds(layerFromNode, layerFromRoot);
    if (
      layerFromNode.includes(rootNodeId) ||
      layerFromRoot.includes(node_id)
    ) {
      continue;
    }
    while (layer > overlaps.length && layer < baseLinks.length) {
      layer++;
      layerFromRoot = findNeighborXLayer(layer, nodesDict, 0);
      layerFromNode = findNeighborXLayer(layer, nodesDict, node_id);
      overlaps = getOverlapIds(layerFromNode, layerFromRoot);
    }
    connectingNodes.push(...overlaps);
  }
  // get node ids that are in between target node -> root
  baseNodes
    .filter((baseNode: EntityDetail) => connectingNodes.includes(baseNode.node_id))
    .forEach((overlapNode: EntityDetail) => {
      if (!newNodeIds.includes(overlapNode.node_id)) newNodeIds.push(overlapNode.node_id);
    });
};

// ***************************** NODE COLAPSIBLE BEHAVIOR
/*
  return nodes and links that should be displayed on screen in collapsed mode.
    We have collapsed mode to optimize rendering for when user has >= 50 nodes
    each node object has "collapsed" + "numberOfNeighbors" properties set
*/
const generateDisplayableGraph = (
  allNodes: any,
  allLinks: any[],
  // additional nodes to be rendered along with root nodes. This module will obtain 1-level neighbors to these nodes for display too.
  centerIds: number[], 
  rootNodeId: number
): { nodes: any[]; links: any[] } => {
  let newNodes: Set<EntityDetail> = new Set();
  let newLinks: Set<LinkDetail> = new Set();
  let nodesDict: { [node_id: number]: number[] } = generateDictNodeConnection(allLinks);

  /*
    1. get the nodes to display ( root + center-Ids + root/center_ids->neighbors + nodes that connect root -> center_Ids)
    2. add those nodes to an array
    3. get the links that connect those nodes
    4. return
  */

  centerIds.push(rootNodeId);

  // mutate centerIds, strip it off of orphan node ids. TODO: handle this more elegantly
  filterOrphanNodesForCollaps(nodesDict, centerIds, rootNodeId);

  /* iterate center Node Ids Array
    populate <newNodes> with center nodes and their first level neighbor.
  */
  for (let centerId of centerIds) {

    let currentNeighborIds: number[] = nodesDict[centerId] || [];

    // get current node
    let currentNode = allNodes.find((node: EntityDetail) => node.node_id == centerId);
    if (!currentNode) {
      console.warn("Display Node Error K10001");  // hope this doesn't happen! Passed in ID of node not exist in baseNodes Array.
      continue;
    }
    currentNode.collapsed = false // this node will be displayed, so collapsed=false
    currentNode.numberOfNeighbors = currentNeighborIds.length; // indicate how many neighbors this node has

    // push this node into newNodes-Array for display
    newNodes.add(currentNode)

    // push first level neighbors of this node in for display too
    for (let currentNeighborId of currentNeighborIds) {
      const currentNeighbor = allNodes.find((node: any) => node.node_id === currentNeighborId);
      if (!currentNeighbor) {
        console.warn("Display Node Error K10002"); // hope this doesn't happen! Link entity contain connection from a node to non-existent node;
        continue;
      }

      currentNeighbor.numberOfNeighbors = nodesDict[currentNeighborId].length;
      currentNeighbor.collapsed = true;
      newNodes.add(currentNeighbor);
    }
  }

  // from <newNodes>, generate <newLinks>: display any link that has connected between displayable nodes in newNodes-array
  const nodeIdsOfInterest = Array.from(newNodes).map(e => e.node_id)
  for (let link of allLinks) {
    const hasSource =
      nodeIdsOfInterest.includes(getNodeIdDrillDown(link.source))
    const hasTarget =
      nodeIdsOfInterest.includes(getNodeIdDrillDown(link.target))
    if (hasSource && hasTarget) {
      newLinks.add(link);
    }
  }

  return {
    nodes: Array.from(newNodes),
    links: Array.from(newLinks)
  };
};

/*
  filter the center nodes ( by ids ) 
    That are not connected to the main root Id
  result: 
    mutate mainCenterIds => strip it off of orphan node ids
*/
const filterOrphanNodesForCollaps = (
  nodesDict: { [node_id: number]: number[] },
  mainCenterIds: number[],
  rootNodeId: number
) => {
  if (!mainCenterIds.includes(rootNodeId) || !nodesDict) return mainCenterIds;
  if (!nodesDict[rootNodeId]) return mainCenterIds;

  // iterate neighbor Ids, remove center Ids and add their neighbors if the node is not an orphan
  for (let i = 0; i < mainCenterIds.length; i++) {
    const id = mainCenterIds[i];
    const isConnected = mainCenterIds.some((currentCenterId: number) => {
      if (currentCenterId === id) return true;
      const currentNeighbors = nodesDict[currentCenterId] || [];
      return currentNeighbors.includes(id);
    });
    if (!isConnected) {
      mainCenterIds.splice(i);
      i--;
    }
  }
};

const setNodeOpacity = (nodeId: number, value: number) => {
  // @ts-ignore
  window.updateAttribute(`#node-circle-${nodeId}`, [
    {
      name: "opacity",
      value: value
    }
  ]);
};

const setActivatedLinkMode = (nodeId: number, activated: boolean) => {
  //@ts-ignore
  window.updateAttribute(`#link-icon-${nodeId}`, [
    {
      name: "xlink:href",
      value: activated ? "/assets/pause.png" : "/assets/link.png"
    }
  ]);
};

export {
  generateDictNodeConnection,
  findNeighborXLayer,
  getOverlapIds,
  checkNodesConnected,
  generateDisplayableGraph,
  setNodeOpacity,
  setActivatedLinkMode,
  getNodesOnPathsFromSelectedNodeToRoot,
  getNodeIdDrillDown,
  rehydrateLinksArray
};
