import React from "react";
import { Menu, Icon, Button, message } from "antd";
import { FeatureDataPackage } from "../../@types";
import FeaturesContext from "../../Context/FeaturesContext";
import { auth, firebaseGoogleAuthProvider, AUTH } from "../../Utils/Firebase"
const { SubMenu } = Menu;

interface Props {
  userSignedIn: boolean
}


function MenuBar(props: Props) {

  const { userSignedIn } = props;

  // keep track of currently selected features
  const {
    setSelectedFeature
  }: {
    selectedFeature: FeatureDataPackage;
    setSelectedFeature: any;
  } = React.useContext(FeaturesContext);


  /*
    login handler when "Login with Google" is clicked
    Called by: Login button
    Result: trigger props.onAuthSuccess
  */
  const loginFirebase = async (e: any): Promise<any> => {

    try {
      // persist auth state
      await auth.setPersistence(AUTH.Persistence.LOCAL)
      const loginResult = await auth.signInWithPopup(firebaseGoogleAuthProvider);

      // invalid login check
      if (!loginResult || !loginResult.credential || !loginResult.additionalUserInfo) {
        throw new Error("Login Invalid, please try again.")
      }

      // get base object from firebase login result
      const credential: any = loginResult.credential;
      const profile: any = loginResult.additionalUserInfo.profile;

      // extract info from firebase login result
      const idToken: string = credential.idToken;
      const email: string = profile.email;
    } catch (err) {
      message.error("Invalid Login, please try again.")
      console.log(err)
      return;
    }

  }

  const logoutFirebase = async (e: any): Promise<any> => {
    await auth.signOut()
  }

  return (
    <Menu mode="horizontal" style={{ zIndex: 5, marginTop: "1%" }}>
      <Menu.Item key="account">

        {userSignedIn ? (
          <Button type="primary"
            style={{ textAlign: "center" }}
            onClick={logoutFirebase}>
            Log out
          </Button>
        ) : (
            <Button
              type="primary"
              onClick={loginFirebase}
              style={{ textAlign: "center" }}
            >
              Login With Google
            </Button>
          )}
      </Menu.Item>
      <SubMenu
        title={
          <span className="submenu-features">
            <Icon type="setting" />
            Feature Catalog
          </span>
        }
      >
        <Menu.ItemGroup title="Graph Features">
          <Menu.Item
            key="import-json-text"
            onClick={e =>
              setSelectedFeature({
                selectedFeatureName: "JSON-IMPORT-DATA"
              })
            }
          >
            Import Data by Json
          </Menu.Item>
          <Menu.Item disabled={true} key="import-text-text">
            Import Data by Text
          </Menu.Item>
          <Menu.Item disabled={true} key="export-json-text">
            Export Data by Json
          </Menu.Item>
          <Menu.Item disabled={true} key="export-text-text">
            Export Data by Text
          </Menu.Item>
          <Menu.Item disabled={true} key="go-public-tree">
            Open Public Tree
          </Menu.Item>
        </Menu.ItemGroup>
        <Menu.ItemGroup title="Account Management">
          <Menu.Item disabled={true} key="go-forum">
            See Forum
          </Menu.Item>
          <Menu.Item disabled={true} key="go-account-actions">
            Account Actions
          </Menu.Item>
          <Menu.Item
            key="go-search-configuration"
            onClick={_ =>
              setSelectedFeature({
                selectedFeatureName: "SEARCH-CONFIG"
              })
            }
          >
            Configure Search
          </Menu.Item>
        </Menu.ItemGroup>
      </SubMenu>
      <SubMenu
        title={
          <span className="submenu-features">
            <Icon type="setting" />
            Tools
          </span>
        }
      >
        <Menu.Item
          key="nightmode"
          onClick={e =>
            setSelectedFeature({
              selectedFeatureName: "STATIC-PAGE",
              page: "nightmode"
            })
          }
        >
          Night Mode
        </Menu.Item>
      </SubMenu>
      <SubMenu
        title={
          <span className="submenu-features">
            <Icon type="info-circle" />
            About Us
          </span>
        }
      >
        <Menu.Item
          onClick={e =>
            setSelectedFeature({
              selectedFeatureName: "STATIC-PAGE",
              page: "credits"
            })
          }
          key="go-credits"
        >
          Credits
        </Menu.Item>
        <Menu.Item
          onClick={e =>
            setSelectedFeature({
              selectedFeatureName: "STATIC-PAGE",
              page: "about"
            })
          }
          key="go-about-us"
        >
          LoopityNote
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
}

export default MenuBar;
