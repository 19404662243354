import React from "react";
import HotkeyContext from "./HotkeyContext";

//https://medium.com/digio-australia/using-the-react-usecontext-hook-9f55461c4eae
const HotkeyProvider = ({ children }: any) => {
  window.onkeydown = (e: any) => {
    setSelectedHotkey(e.key);
  };
  window.onkeyup = (e: any) => {
    clearSelectedHotkey(e.key);
  };
  const setSelectedHotkey = (hotkeyName: string) => {
    if (!currentHotkeys.find(key => key === hotkeyName)) {
      setCurrentHotkeys([...currentHotkeys, hotkeyName]);
    }
  };
  const clearSelectedHotkey = (hotkeyName: string) => {
    const indexHotkey = currentHotkeys.indexOf(hotkeyName);
    if (indexHotkey >= 0) {
      currentHotkeys.splice(indexHotkey, 1);
      setCurrentHotkeys([...currentHotkeys]);
    }
  };
  const clearAllSelectedHotkey = () => {
    setCurrentHotkeys([]);
  };
  const [currentHotkeys, setCurrentHotkeys] = React.useState<string[]>([]);

  if (currentHotkeys.includes(" ")) {
    // @ts-ignore
    window.simulateForceVelocity();
  }

  return (
    <HotkeyContext.Provider
      value={{
        currentHotkeys,
        setSelectedHotkey,
        clearSelectedHotkey,
        clearAllSelectedHotkey
      }}
    >
      {children}
    </HotkeyContext.Provider>
  );
};

export default HotkeyProvider;
